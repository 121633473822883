<template>
  <div class="container-fluid">
    <p class="row">{{ $t('message.budget_code_intro') }}
      <br>
      {{ $t('message.more_info') }} <a href="/mode_emploi_Coffre_Code_Budget.pdf" target="_blank">{{ $t('message.code_budget_user_guide') }}</a> <a v-bind:href="this.faqUrl" target="_blank">{{ $t('message.faq_other') }}</a>.

    </p>
    <div v-if="errorMessage" class="m-auto mdl-color-text--red-500 text-center">
      <span>{{ this.errorMessage }}</span>
      <br>
      <br>
    </div>
    <button type="button" class="btn btn-primary d-block btn-connect" id="btn-login-budget-code" @click="loginCAS">
      <strong>{{ $t('message.login_with_intranet') }}</strong>
    </button>
  </div>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import config from '../../config'

export default {
  name: 'LoginBudgetCode',
  data: () => ({
    errorMessage: null
  }),
  created () {
    this.$emit('update:layout', LoginLayout)

    const error = this.$route.query.error?.toString()

    if (error) {
      if (error === '101') {
        this.errorMessage = this.$t('message.error_login')
      }

      if (error === '102') {
        this.errorMessage = this.$t('message.no_budget_code')
      }

      if (error === '103') {
        this.errorMessage = this.$t('message.access_expired')
      }
    }

    document.title = config.title + ' - ' + this.$t('message.login')

    const tusElements = []

    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 3) === 'tus') {
        tusElements.push(localStorage.key(i))
      }
    }

    for (let i = 0; i < tusElements.length; i++) {
      localStorage.removeItem(tusElements[i])
    }
  },
  computed: {
    faqUrl () {
      if (window.location.origin.includes('ecoffre-intranet')) {
        return 'https://mon-ecoffre.com/faq?type=cfrb'
      }
      if (window.location.origin.includes('ppr-intranet')) {
        return 'https://cfnv2-ppr.fiducialcloud.fr/faq?type=cfrb'
      }
      return window.location.origin + '/faq?type=cfrb'
    }
  },
  methods: {
    loginCAS () {
      location.href = config.casUrl + '/CASServer/login.aspx?service=' + config.casCallbackUrl
    }
  }
}
</script>

<style lang="scss">
#btn-login-budget-code {
  margin: 0 auto;
  width: auto;
  background: #0A93A2;
}
</style>
